import { Environment } from './environment.interface';
export { Environment } from './environment.interface';

export const environment: Environment = {
  production: false,
  brands: {
    enelx: {
      baseUrl: 'https://app.customer-offers.dev-apps.enelx.net/v1',
      globalNavUrl: 'https://global-nav-v2.dev-svcs.enernoc.net/api/v2'
    }
  },
  cdn: 'https://cdn.dev-apps.enelx.net',
  defaults: {
    brand: 'enelx',
    locale: 'en-us',
    apiLocale: 'en_US'
  }
};
